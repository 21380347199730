import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './RegisterPage.css'; // Importing updated stylesheet

const RegisterPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [interest, setInterest] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const navigate = useNavigate(); // Initialize navigate

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const createAzureAccount = axios.post('https://api.infradelta.com/user/createazureaccount', {
        body: JSON.stringify({
          displayName: firstName,
          mailNickname: firstName,
          userPrincipalName: email
        })
      });

      const register = axios.post('https://api.infradelta.com/auth/register', {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        interest: interest
      });

      // Use Promise.all to call both APIs concurrently
      const [azureResponse, registerResponse] = await Promise.all([createAzureAccount, register]);
    
      // Handle success response from both APIs
      console.log('Azure Response:', azureResponse.data);
      console.log('Register Response:', registerResponse.data);

      alert('User successfully created in both Azure and DynamoDB');
      navigate('/dashboard');

    } catch (error) {
      console.error('Error occurred while calling the APIs:', error);
      alert('Failed to register user in one or both services');
    }
  };

  return (
    <div className="register-container">
      <h2>Sign Up</h2>
      <form onSubmit={handleRegister}>
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Area of Interest"
          value={interest}
          onChange={(e) => setInterest(e.target.value)}
        />
        <button type="submit" className="register-button">Create Account</button>
      </form>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
    </div>
  );
};

export default RegisterPage;
