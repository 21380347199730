import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext'; // Import the custom hook
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import './LoginPage.css'; // Importing stylesheet

const LoginPage = () => {
  const { login } = useUser();  // Use the login function from context
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();  // Hook to navigate between pages


// handle Login

  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent form submission
  
    const payload = {
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    };

    try {
      const response = await fetch('https://api.infradelta.com/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload), // Send email and password
      });
  
      const rawResponse = await response.text();
      console.log('Raw Response:', rawResponse); // Log the raw response text
  
      let data;
      try {
        data = JSON.parse(rawResponse); // Parse the stringified JSON body
      } catch (error) {
        console.error('Error parsing response body:', error);
        setError('An error occurred while processing the response.');
        return;
      }
  
      console.log('Parsed Data:', data); // Log the parsed data
  
      // Handle successful login (statusCode 200 inside body)
      if (data.statusCode === 200) {
        const user = JSON.parse(data.body); // Handle the stringified body in the data
        
        console.log('Parsed User Data:', user);
  
        if (user && user.email && user.firstName && user.lastName) {
          setError('');  // Clear previous errors
          login(user);  // Store the user data
          navigate('/dashboard');  // Redirect to Dashboard
        } else {
          setError('An error occured');
        }
      } 
      // Handle "User not found" error (statusCode 404 inside body)
      else if (data.statusCode === 404) {
        console.log('User not found, statusCode 404');
  
        if (data.body && JSON.parse(data.body).error === 'User not found') {
          setError('User not found. Redirecting to registration.');
          navigate('/register');  // Redirect to registration page
        } else {
          setError(data.error || 'An error occurred. Please try again.');
        }
      } 
      else if (data.statusCode === 401) {
        console.log('Incorrect Username or Password');
        setError('Incorrect Username or Password.');
      } 
      // Handle Internal Server Error (statusCode 500)
      else if (data.statusCode === 500) {
        console.error('Internal Server Error:', data.body);
        setError('Server error. Please try again later.');
      } 
      // Handle other errors
      else {
        setError(data.error || 'An error occurred. Please try again.');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('An error occurred. Please try again.');
    }
  };
  


// handle login end


  const handleGoogleSuccess = (response) => {
    const token = response.credential;
    try {
      const decoded = jwtDecode(token);

      console.log('Decoded JWT:', decoded); // Debug log to verify decoded data

      const { email, given_name: firstName, family_name: lastName } = decoded;

      // Save the user info to context
      login({
        firstName,
        lastName,
        email,
      });

      setError(''); // Clear any previous error
      navigate('/dashboard'); // Navigate to dashboard page
    } catch (error) {
      setError('Google login failed. Please try again.');
      console.error('Google login error:', error);
    }
  };

  const handleGoogleFailure = (error) => {
    setError('Google login failed. Please try again.');
    console.error('Google login error:', error);
  };


  
  return (
    <div className="login-container">
      <div className="login-box">
        <header className="login-header">
          <img
            src="/logo.png" // Replace with your logo path
            alt="Logo"
            className="logo"
          />
          <h1>Welcome to Your AI Workspace</h1>
          <p>Login to access the future of AI-powered solutions.</p>
        </header>

        {/* Google OAuth Login */}
        <GoogleOAuthProvider clientId="301510546215-b89i4spqa724cg9ihsrldluj1o3q5gpk.apps.googleusercontent.com">
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleFailure}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="google-login-button"
              >
                Sign in with Google
              </button>
            )}
          />
        </GoogleOAuthProvider>

        {/* "OR" text between Google login and Email Login */}
        <p className="or-text">OR</p>

        <form className="login-form" onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="input-field"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="input-field"
          />
          <button type="submit" className="login-button">Login</button>
        </form>

        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default LoginPage;
