// App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import Home from './Home'; // Import the Home component

import LoginPage from './components/LoginPage'; // Use the correct path
import RegisterPage from './components/RegisterPage'; // Use the correct path
import Chatbot from './components/Chatbot';
import DashboardPage from './components/DashboardPage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} /> {/* Home page */}
      <Route path="/login" element={<LoginPage />} /> {/* Login page */}
      <Route path="/register" element={<RegisterPage />} /> {/* Login page */}
      <Route path="/chatbot" element={<Chatbot />} /> {/* Login page */}
      <Route path="/dashboard" element={<DashboardPage />} /> {/* Login page */}

    </Routes>
  );
}

export default App;
