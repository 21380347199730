import React, { useState, useEffect, useRef } from "react";
import { useUser } from "./UserContext"; // Ensure the correct path to your UserContext
import "./Chatbot.css"; // Ensure your CSS file is imported

const Chatbot = () => {
  const { user } = useUser(); // Fetch user from context
  const [messages, setMessages] = useState([]);
  const flattenedMessages = messages.flat(); 
  const [currentMessage, setCurrentMessage] = useState("");
  const [input, setInput] = useState("");

  // Ref to the last message
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (user && user.email) {
      fetchMessageHistory(user.email);
    }
  }, [user]);

  // Scroll to the last message whenever messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const fetchMessageHistory = async (email) => {
    try {
      const response = await fetch(`https://api.infradelta.com/chat/getmessages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          body: JSON.stringify({
            email: email,
          }),
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch message history for ${email}`);
      }

      const data = await response.json();
      console.log("Fetched chat message history:", data);
      
        // Check if the response body is a valid JSON string and parse it
        if (data && data.body) {
          try {

            const parsedBody = JSON.parse(data.body); // Parse the stringified body
            
            if (Array.isArray(parsedBody.messages)) {
              setMessages(parsedBody.messages); // Set messages if valid
            } else {
              console.error("Messages are not in the expected array format:", parsedBody);
            }
          } catch (parseError) {
            console.error("Error parsing response body:", parseError);
          }
        } else {
          console.error("Invalid response structure:", data);
        }

    } catch (error) {
      console.error("Error fetching message history:", error);
    }
  };

  const handleSend = async () => {
    if (!input) return; // Do nothing if input is empty

    if (!user || !user.email) {
      console.error("User is not defined or missing email.");
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "assistant",
          content: "User information is missing. Please log in again.",
          sender: "AI Operator",
        },
      ]);
      return;
    }

    const userMessage = {
      role: "user",
      content: input,
      sender: user.firstName || "You", // Use `user.firstName` or default to "You"
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);

    setInput(""); // Clear the input field

    try {
      const response = await fetch("https://api.infradelta.com/chat/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          body: JSON.stringify({
            email: user.email,
            message: input,
          }),
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json(); // Parse the JSON response safely
      console.log('Response from Lambda:', data.body);

      let replyMessage;
      try {
        // Attempt to parse the `body` field if it's JSON
        const parsedBody = data.body ? JSON.parse(data.body) : null;
        
        replyMessage = parsedBody?.reply || "No response provided by AI.";
      } catch (parseError) {
        console.error("Failed to parse response body:", parseError);
        replyMessage = "The server response could not be processed.";
      }

      const aiMessage = {
        role: "assistant",
        content: replyMessage,
        sender: "AI Operator",
      };

      setMessages((prevMessages) => [...prevMessages, aiMessage]);

    } catch (error) {
      console.error("Error in handleSend:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "assistant",
          content: "Something went wrong. Please try again later.",
          sender: "AI Operator",
        },
      ]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h2 align='center'>Welcome to the AI Engine</h2>
        <h3>Hi, {user.firstName || "User"}!</h3>

      </div>

      <div className="chat-window">
        {flattenedMessages.length > 0 ? (
          flattenedMessages.map((msg, index) => {
            const { content, role } = msg; // Destructure content and role
            const messageContent = content || "No response provided by AI."; // Fallback message
            console.log("Rendering message - Content:", messageContent, "Role:", role);

            return (
              <div
                key={index}
                className={`chat-message ${role === "user" ? "user-message" : "ai-message"}`}
              >
                <div className="message-author">
                  <strong>{role === "user" ? user.firstName : "AI Operator"}:</strong>
                </div>
                <div className="message-content">
                  <p>{messageContent}</p> {/* Fallback */}
                </div>
              </div>
            );
          })
        ) : (
          <p>Please start your conversation here..!</p>
        )}
        {/* This element will always be at the bottom to scroll to */}
        <div ref={messagesEndRef} />
      </div>

      <div className="chat-input-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type your message..."
          className="chat-input"
        />
        <button onClick={handleSend} className="chat-send-button">
          Send
        </button>
      </div>
    </div>
  );
};

export default Chatbot;
