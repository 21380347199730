// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';  // Import the BrowserRouter

import App from './App';
import { UserProvider } from './components/UserContext'; // Import UserProvider

ReactDOM.render(
  <UserProvider> {/* Wrap your app with UserProvider */}
     <BrowserRouter>
    <App />
  </BrowserRouter>
  </UserProvider>,
  document.getElementById('root')
);
