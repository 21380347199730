// Dashboard.js
import React, { useState, useEffect } from "react";
import Chatbot from "./Chatbot"; // Import the chatbot component
import { useUser } from "./UserContext"; // Ensure the correct path to your UserContext
import "./DashboardPage.css"; // Ensure your CSS file is imported


const Dashboard = () => {

  const { user } = useUser(); // Fetch user from context
// Add placeholder functions for button clicks (customize later)
const handleUploadDataset = () => {
  console.log("Upload Dataset clicked");
  // Add your upload dataset logic here
};

const handleBuildDeployModel = () => {
  console.log("Build/Deploy Model clicked");
  // Add your build/deploy model logic here
};

return (
  <div className="dashboard-container">
    {/* Page Header Menu */}

    {/* Chatbot Container */}
    <div className="chatbot-container">
      <Chatbot user={user} />
    </div>

    {/* Dashboard Buttons */}
    <div className="dashboard-buttons">
      <button className="dashboard-button">Upload Dataset</button>
      <button className="dashboard-button">Build / Deploy Model</button>
      <button
          className="dashboard-button"
          onClick={() => window.location.href = '/login'} // Replace '/login' with your login page URL
        >
          Home
        </button>
    </div>

  </div>
);
};
export default Dashboard;
